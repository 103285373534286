.stars {
    display: inline-block;
}

.stars .star {
    height: 20px;
    float: left;
    padding: 2px;
    width: 20px;
    position: relative;
    border-radius: 4px;
    background: #cecece;
    margin-right: 2px;
}

.stars .star:before {
    content: "";
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-image: url(/icons/star-white.svg);
    position: absolute;
    top: 4px;
    left: 4px;
    right: 4px;
    bottom: 4px;
    z-index: 1;
}

.stars .star:after {
    content: "";
    border-radius: 4px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    -webkit-transition: all .1s linear;
    transition: all .1s linear;
}

.star.star--full:after, .star.star--half:after {
    background: #f8be12;
}