/**
 * This injects Tailwind's base styles, which is a combination of
 * Normalize.css and some additional base styles.
 *
 * You can see the styles here:
 * https://github.com/tailwindcss/tailwindcss/blob/master/css/base.css
 *
 * If using `postcss-import`, use this import instead:
 *
 * @import "tailwindcss/base";
 */
@tailwind base;

/**
 * This injects any component classes registered by plugins.
 *
 * If using `postcss-import`, use this import instead:
 *
 * @import "tailwindcss/components";
 */
@tailwind components;

/**
 * Here you would add any of your custom component classes; stuff that you'd
 * want loaded *before* the utilities so that the utilities could still
 * override them.
 *
 * Example:
 *
 * .btn { ... }
 * .form-input { ... }
 *
 * Or if using a preprocessor or `postcss-import`:
 *
 * @import "components/buttons";
 * @import "components/forms";
 */

/**
 * This injects all of Tailwind's utility classes, generated based on your
 * config file.
 *
 * If using `postcss-import`, use this import instead:
 *
 * @import "tailwindcss/utilities";
 */
@tailwind utilities;

/**
 * Here you would add any custom utilities you need that don't come out of the
 * box with Tailwind.
 *
 * Example :
 *
 * .bg-pattern-graph-paper { ... }
 * .skew-45 { ... }
 *
 * Or if using a preprocessor or `postcss-import`:
 *
 * @import "utilities/background-patterns";
 * @import "utilities/skew-transforms";
 */
* {
    box-sizing: border-box;
    font-family: 'Open sans', sans-serif;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.button {
    border: 1px solid #3979f2;
    background: #3979f2;
    color: white;
    box-shadow: inset 0 -1px 0 #19427d, 0 1px 2px rgba(0, 0, 0, 0.2);
}

.chevron-down {
    display: block;
    width: 7px;
    height: 7px;
    position: absolute;
    right: 25px;
    transform: translateY(-50%) rotate(45deg);
    top: 50%;
    border-bottom: 2px solid #3979f2;
    border-right: 2px solid #3979f2;
    transition: 0.4s;

    &.active {
        transform: translateY(-50%) rotate(-135deg);
    }
}

.select-with-chevron {
    background: #fff url('/images/select-arrow.png') right 10px center no-repeat;
}

@import "loading";
@import "stars";
@import "helpers";
@import "toasteo";
@import "tooltip";


@responsive {
    .flex-no-overflow {
        flex: 1 1 0;
        min-width: 0;
    }

    .no-truncate {
        overflow: auto;
    text-overflow: unset;
    white-space: inherit;
    }

    .break-words-gracefully {
        overflow-wrap: break-word;
        word-wrap: break-word;

        -ms-word-break: break-all;
        /* This is the dangerous one in WebKit, as it breaks things wherever */
        word-break: break-all;
        /* Instead use this non-standard one: */
        word-break: break-word;

        /* Adds a hyphen where the word breaks, if supported (No Blink) */
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;
    }
}

.pagination {
    @apply flex shadow;

    a {
        @apply inline-block bg-white text-primary py-2 px-4 border-t border-l border-b border-gray-100 text-gray-600;

        &:hover {
            @apply text-primary bg-gray-50;
        }

        &:focus {
            outline: none !important;
        }

        &.active {
            @apply bg-primary text-white;
        }

        &.prev {
            @apply rounded-l;
        }

        &.next {
            @apply border-r rounded-r;
        }

        &:not(.prev):not(.next) {
            display: none;
        }
    }
}

@media only screen and (min-width: 600px) {
    .pagination a {
        &:not(.prev):not(.next) {
            display: block;
        }
    } 
}

// Support page printing
@media print {
    .h-top-bar {
        display: none;
    }
    .pt-top-bar {
        padding-top: 0 !important;
    }
    .h-screen {
        height: auto;
    }
    .min-h-screen {
        min-height: initial;
    }
    .max-h-screen {
        max-height: initial;
    }
    .overflow-y-auto {
        overflow-y: visible;
    }
}